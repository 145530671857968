.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  
    margin: 4rem 0 2rem;
  
    .app__work-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      color: #000;
      font-weight: 800;
  
      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;
  
      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }
  
    .item-active {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }
  
  .app__work-portfolio {
    width: 90%;
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
  
    display: flex;
    flex-direction: row;
  
    @media screen and (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }
  }
  

  .pdf {
    height: 500px;
    width: 1100px;

    @media screen and (max-width: 900px) {
       height: 500px;
       width: 300px;        
    }
    @media screen and (min-width: 2000px) {
        height: 900px;
        width: 1800px;        
    }
}

.accordion-simple > .active {
  display: block;
}

.accordion__faq .inactive{
  display: none;
}

.accordion__faq > div{
  background-color: var(--white);
  margin-bottom: 20px;
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.accordion__faq-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vertical{
  display: block;
 float: right;
}

.active.not {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
  opacity: 0;
}

.active {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
  opacity: 1;
}
