.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }
  
  .app__skills-container {
    width: 90%;
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
  
    display: flex;
    flex-direction: row;
  
    @media screen and (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }
  }

  .pdf {
    height: 500px;
    width: 1100px;

    @media screen and (max-width: 900px) {
       height: 500px;
       width: 300px;        
    }
    @media screen and (min-width: 2000px) {
        height: 900px;
        width: 1800px;        
    }
}

.accordion-simple > .active {
  display: block;
}

.accordion__faq .inactive{
  display: none;
}

.accordion__faq > div{
  background-color: var(--white);
  margin-bottom: 20px;
  padding: 0px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.accordion__faq-heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vertical{
  display: block;
 float: right;
}

.active.not {
  max-height: 0;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0,1,0,1);
  opacity: 0;
}

.active {
  height: auto;
  max-height: 9999px;
  transition: all 0.5s cubic-bezier(1,0,1,0);
  opacity: 1;
}
